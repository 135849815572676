
:root {
    --primary-color: #1E293B;
    --secondary-color: #F3F3F3;   
  }
select:focus-visible {
  outline: none;
}
.font-600 {
  font-weight: 600;
}
.cursor-pointer {
  cursor: pointer;
}
.blue-color {
    color: var(--primary-color);
}
.heading4 {
    font-size: 20px;
    line-height: 25px;
}
a{
    text-decoration: none;
}
a:hover{
    text-decoration: none;
}
input:focus-visible, textarea:focus-visible {
 outline: none;
}
button:focus {
  outline: none;
}
textarea {
  resize: none;
}
.detailed_fix {
  max-height: 94px;
    overflow-y: auto;
}



@media screen and (min-width: 1500px) {
    .container {
      max-width: 1420px;
    }
  }
  @media screen and (min-width: 1600px) {
    .container {
      max-width: 1520px;
    }
  }
  @media screen and (min-width: 1700px) {
    .container {
      max-width: 1640px;
    }
  }
  @media screen and (min-width: 1800px) {
    .container {
        max-width: 1666px;
    }
}