


.start-home {
  left: 0;
}
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  margin-top: 60px;
}

header .nav_bar {
  display: flex;
  align-items: center;
  background: #BDBDBD;
  border-radius: 35px;
  padding: 0;
  justify-content: space-between;    
  overflow: hidden;
}
header .nav_bar.empnav {
  width: 50%;
    margin: 0 auto;
}
.nav-button {
  flex: 1;
  padding: 10px 20px;
  border: none;
  border-radius: 20px; /* Rounded corners for each button */
  background-color: transparent;
  color: #000;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}
.nav-button:hover {
  background-color: #E5E7EB; /* Slightly darker gray on hover */
}
.nav-button.active {
  background-color: #1E293B; /* Dark blue background for active button */
  color: #fff; /* White text for active button */
}
header .nav-link {
  color: #000;
  text-decoration: none;
  border-radius: 30px;
  transition: background-color 0.3s;
  padding: 17px 20px;
  width: 100%;
  text-align: center;
  font-size: 20px;
  line-height: 25px;
  position: relative;
}


header .nav-link.active {
  background-color: #1E293B;
  color: #fff;
}  
  .user_notification {
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;
    color:black;
    justify-content: flex-end;
  }
  .username {
    font-size: 20px;
    line-height: 24px;
  }
  

  
  .search-results {
    position: absolute;
    top: 50px;
    right: 10px;
    background: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 200px;
  }
  
  .search-results ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .search-results li {
    padding: 10px;
    border-bottom: 1px solid #eee;
  }
  
  .search-results li:last-child {
    border-bottom: none;
  }




  .animation {
    position: absolute;
    height: 100%;
    top: 0;
    z-index: 0;
    transition: all 0.5s ease 0s;
    border-radius: 35px;
  }




  