/*    Request   Section   */

@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

body {
    font-family: "Inter", sans-serif;
    background-color: #d4dbe0;
    margin: 0;
    padding: 0;
}
.action-icon {
    margin: 0 5px;
    width: 20px;
    height: 20px;
    cursor: pointer;
}


.icon {
    margin-right: 5px;
}
.full-screen-form {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    /* Dim the background */
    z-index: 9999;
}

.form-container {
    background: #ffffff;
    padding: 40px;
    border-radius: 10px;
    max-width: 1079px;
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Form title */
.form-container h2 {
    color: #333;
    text-align: center;
    margin-bottom: 20px;
}

/* Form error message */
.error {
    color: red;
    text-align: center;
    margin-bottom: 20px;
}

/* Form group styling */
.form-group {
    margin-bottom: 20px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    color: #333;
}

.form-group input,
.form-group select,
.form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.form-group-row {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.form-group-row .form-group {
    flex: 1;
}

/* Submit button styling */
.submit-button {
    width: 100%;
    padding: 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
}

.submit-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}



.table-container {
    margin-top: 40px;
}
.table-container .table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 20px;
}
.table-container .table th {
    border: none;
    text-transform: uppercase;
    text-align: center;
  }

.table-container .table_tr {
    overflow: hidden;
    background-color: #ffffff;
    cursor: pointer;
    box-shadow: #d4dbe0 0px 4px 16px, #d4dbe0 0px 8px 24px, #d4dbe0 0px 16px 56px;
}
.table-container .table_tr:hover {
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
}
.table-container .table_tr td {
    padding: 15px;
    font-size: 20px;
    line-height: 25px;
    vertical-align: middle;
    color: #000;
    text-align: center;
}

.table-container td:first-child {
    border-bottom: none;
    border-radius: 10px 0 0 10px;
}

.table-container td:last-child {
    border-bottom: none;
    border-radius: 0 10px 10px 0;
}
.btn_type {
    max-width: 158px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    margin: 0 auto;
}
.btn_type h6 {
    margin: 0 auto;
    font-size: 18px;
    line-height: 20px;
}
.btn_type.new_button {
    background: #ddf6e8;
}

.btn_type.new_button h6 {
    color: #55d48e;
}
.btn_type.damage_button {
    background: #f2dbda;
}
.btn_type.damage_button h6 {
    color: #cd2b2b;
}
.action_icon {
    opacity: 0.7;
    margin-right: 10px;
}
.action_icon:hover {
    opacity: 1;
}
.pagination {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.pagination button {
    margin: 0 5px;
    border: none;
    cursor: pointer;
    background: transparent;
    width: 35px;
    height: 35px;
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
}

.pagination button.active {
    background-color: var(--primary-color);
    color: white;
    border-radius: 100%;
}

.pagination button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
}
.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    backdrop-filter: blur(3px);
}
.modal .modal-content {
    background-color: #1e293b;
    color: white;
    padding: 55px 30px 30px 30px;
    border: none;
    border-radius: 10px;
    width: 28%;
    align-items: start;
}
.modal .close-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
}
.btn {
    color: var(--primary-color);
    font-size: 20px;
    line-height: 26px;
    padding: 10px 36px;
    border-radius: 35px;
    background-color: transparent;
    border: 2px solid var(--primary-color);
    font-weight: 600;
    /* width: 200px; */
    width: auto;
}
.btn:hover {
    background-color: #2c3e50;
    color: #fff;
}
.btn2 {
  color: var(--white);
    font-size: 16px;
    line-height: 20px;
    padding: 10px 36px;
    border-radius: 35px;
    background-color: transparent;
    border: 1.5px solid var(--white);
    font-weight: 600;
}
.btn2:hover {
  color: #2c3e50;
  background: var(--white);
}

.action_btns {
    display: flex;
    gap: 20px;
    margin-top: 40px;
}

.hidden {
    display: none;
}
.priority_btn {
  font-weight: 600;
}
.priority_btn.low {
color: #A2C6EB;
}
.priority_btn.medium {
  color: #4F76C8;
}
.priority_btn.low {
  color: #A2C6EB;
  }
.priority_btn.high {
  color: #3049A1;
  }
.priority_dot {
  display: inline-block;
  width: 17px;
  height: 17px;
  border-radius: 100%;
  vertical-align: middle;
  margin-right: 5px;
}
.priority_dot.low {
  background-color: #A2C6EB;
}
.priority_dot.mediam {
  background-color: #4F76C8;
}
.priority_dot.high {
  background-color: #3049A1;
}
.status.reject {
    background-color: #f8d7da;
    color: #721c24;
    padding: 5px 10px;
    border-radius: 5px;
}
.btn_status {
  padding: 10px;
    text-align: center;
    border-radius: 10px;
    max-width: 155px;
    margin: 0 auto;
    font-size: 17px;
    line-height: 22px;
    height: auto;
    position: relative;  
    overflow: unset;
}
.dropdown-content {
  position: absolute;
    top: 45px;
    left: 0;
    background: azure;
    z-index: 1;
    width: 115%;
    border-radius: 10px;
    text-align: left;
    padding: 10px 0;  
}
.dropdown-item {
  font-size: 20px;
    line-height: 24px;
}
.btn_status.hold, .btn_status.Hold {
background-color: #FFE8D2;
color: #FFAC5B;
}

.btn_status.complete, .btn_status.complete   {
  background-color: #DDF6E8;
  color: #55D48E;
}
.btn_status.pending, .btn_status.Pending   {
  background-color: #EBE6D6;
  color: #828282;
}
.btn_status.reject {
  background-color: #F2DBDA;
  color: #FF6C64;
  }
.btn_status.inactive, .btn_status.in.active {
  background-color: #FFF5CF;
  color: #FFD11C;
  }
.btn_status.in.progress, .btn_status.inprogress {
  background-color: #D1F3F7;
  color: #40CBDD;
  } 
  .dropdown-container {
    position: relative;
  }
  

.searchbar-top {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}
.content-title {
    color: var(--primary-color);
    font-size: 45px;
    line-height: 45px;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
}
.searchbar-top .search_bar {
    position: relative;
    padding: 5px 10px;
    border-radius: 10px;
    background-color: #d4dbe0;
    border: 1px solid var(--primary-color);
    max-width: 224px;
    height: 38px;
}
.search-input {
    background-color: transparent;
    width: 100%;
    border: none;
    height: 100%;
    padding-right: 37px;
}
.searchbar-top .search_bar .search-icon {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    background: var(--primary-color);
    padding: 10px 12px;
    border-radius: 0 8px 8px 0;
}
.loading-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px; 
}
.loading-dots {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60px;
}
.loading-dots div {
  width: 10px;
  height: 10px;
  background-color: #333;
  border-radius: 50%;
  animation: loading-dots 0.8s infinite;
}
.loading-dots div:nth-child(1) {
  animation-delay: 0s;
}
.loading-dots div:nth-child(2) {
  animation-delay: 0.2s;
}
.loading-dots div:nth-child(3) {
  animation-delay: 0.4s;
}
@keyframes loading-dots {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}
.form {
  width: 100%;
  background: #fff;
  padding:90px;
  border-radius: 30px;
  margin-top: 70px;

}
.form .input-container {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  width: 100%;
  max-width: 500px; /* Adjust the max width as needed */
  box-sizing: border-box;
  margin-bottom: 16px; /* Space between input fields */
}

.form .input-label {
  font-weight: bold;
  margin-right: 10px;
  white-space: nowrap; /* Prevent label from wrapping */
}

.form .input-field {
  flex-grow: 1; /* Allow the input field to expand to fill the container */
  border: none;
  outline: none;
  padding: 5px;
  font-size: 16px;
  background-color: transparent;
}

.form .input-field::placeholder {
  color: #aaa;
}

.form .error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}
fieldset {
  width: 100%;
  display: block;
  padding: 5px 22px;
  border: 1.5px solid #929292;
  border-radius: 16px;
  margin-bottom: 45px;
  position: relative;
}
legend {
  width: auto;
    color: #1C1C1C;
    font-weight: 600;
    margin-bottom: 0;
    line-height: normal;
    font-size: 20px;
}
.form select, 
.form input, .form textarea {
  width: 100%;
    border: none;
    height: 100%;
    padding: 5px 0 12px 0;
    cursor: pointer;
}
/* .form .btn {
  display: block;
    margin: 0 auto;
} */


.img_model .modal_content {
  display: flex;
  flex-wrap: wrap;
  width: 35%;
  background-color: #1e293b;
  color: white;
  padding: 55px 30px 30px 30px;
  border: none;
  border-radius: 10px;
  align-items: center;
  position: relative;
  row-gap: 21px;
}

.img_model .img_secs {
  background: #fff;
  padding: 15px;
  border-radius: 15px;
  text-align: center;
}

.img_model .content {
  padding-left: 18px;
}
.img_model legend {
  color: #fff;
}
.img_model fieldset {
  margin-bottom: 0;
}
.img_model textarea {
  background: transparent;
    border: none;
    width: 100%;
    height: 100%;
    color: #fff;
    resize: none
}
.img_model .modal_content p {
  margin-bottom: 10px;
}

.delete_model.modal .modal-content{
  text-align: center;
    align-items: center;
    width: 22%;
}
.delete_model.modal .delete-image {
  width: 100px;
}
.modal.scanimg_model {
  background: #0000004f;
  backdrop-filter: unset;
}
.scanimg_model .qrcode-img {
  border-radius: 20px;
}
.scanimg_model .modal-content {
  width: 23%;
  background: transparent;
}
/* .img_blog {  
  overflow: hidden;
} */
.assest_scan .img_blog::after {  
  content: '';
position: absolute;
left: 0;
top: 0;
background: #ffffff45; 
width: 100%;
height: 100%;
display: none;
}
.assest_scan .img_blog:hover::after {
display: block;
}

.assest_scan .download-icon {
  position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    background: #000;
    border-radius: 5px;
    padding: 8px;
    width: 35px;
    height: 35px;
    display: none;
    z-index: 1;
}
.assest_scan  .img_blog:hover .download-icon{
  display: block;
}
.img_blog {
  border: 3px solid rgb(0, 0, 0);
    border-radius: 16px;
    position: relative;
    overflow: hidden;
}
/* .download-icon {
  display: none;
} */

/* img:hover + .download-icon {
  display: block;
} */
.scanpage {
  backdrop-filter:unset;
  background: #d4dbe0;
  flex-direction: column;
  gap: 20px;
  justify-content: start;
  margin-top: 60px;
}
.scanpage .atstitle {
  position: relative;
  padding-bottom: 25px;
}
.scanpage .atstitle::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 3px;
  background: linear-gradient(90deg, rgba(212,212,212,1) 0%, rgba(51,51,51,1) 50%, rgba(212,212,212,1) 100%);
}
.scanpage .modal-content{
  border-radius: 19px;
  /* width: 20%; */
  width: fit-content;
  margin-top: 60px;
}
.modal.scanimg_model .close-icon {
  top: 10px;
    right: 11px;
    width: 29px;
}
.selected-items {
  display: flex;
  gap: 5px;
  top: 38%;
  left: 36px;
  position: absolute;
  transform: translateY(-50%);
  width: 82%;
  background: #fff;
  flex-wrap: wrap;
}

.selected-item {
  background-color: #f0f0f0; 
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}


.selected-item:hover {
  background-color: #d0d0d0;
}
.allocate_item_details {
  display: flex;
  gap: 10px;
  margin-bottom: 45px;
  flex-wrap: wrap;
}
.allocate_item_details input {
  width: auto;
  cursor: pointer;
  width: 26px;
}
.allocate_item_details .item {
  align-items: center;
    display: flex;
    background: white;
    padding: 10px;
    border-radius: 25px;
    gap: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.data_noavailble {
  display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    margin: 10% 0;
    text-transform: capitalize;
    font-size: 42px;
}

@media screen and (max-width: 1024px) {
  .table-container {
    overflow-x: scroll;
}
  /* .scanpage .modal-content {
    width: 75%;
  } */
}
@media screen and (max-width: 767px) {
.scanpage .atstitle {
  font-size: 30px;
  text-align: center;
}
}
/* vandana */
